* {
    overflow: hidden;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600;700&display=swap');


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.home{
    
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;

    background: rgba(220, 244, 226, 1.0);

    background: radial-gradient(at left bottom, rgba(220, 244, 226, 1), rgb(202, 236, 212, 1), rgb(202, 236, 212, 1), rgba(220, 244, 226, 1), rgb(180, 234, 193));
	background-size: 400% 400%;
	animation: gradient 30s ease infinite;
	height: 100vh;
}



.home-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95vw;
    height: 80vh;
    background: rgba(220, 244, 226, 0.91);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #B3DFBE;
    font-family: 'Inter', sans-serif;
    color: #115B33;

    font-size: 1.5rem;
    border-radius: 30px;

    
}

h1{
    font-weight: 600;
    letter-spacing: -.025em;
    font-size: 30px;
}

.typeface{
    font-size: 4rem;
    font-weight: 700;

}

.ruita{
    letter-spacing: -.025em;
}

/* .doodle{
    overflow: hidden;
    color: #115B33;
    position: absolute;
    z-index: 3;
    filter: drop-shadow(7px 21px 23px #0e4d2bab);
    
    left: min(-150vh, -80vw);
    width: 130vw;
} */

.doodle{
    overflow: hidden;
    color: #115B33;
    position: absolute;
    z-index: 3;
    filter: drop-shadow(7px 21px 23px #0e4d2bab);
    
    width: max(1000px, 100vw);
    left: 0;
    margin-left: min(-600px, -50vw);

}

.soon{
    color: #4e7457;
}

::selection {
    background: #8ebe9a; /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    background: #8ebe9a; /* Gecko Browsers */
  }

.background-text{
    font-size: 10rem;
    font-weight: 700;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
    opacity: 0.1;
    margin-right: 26px;
}

@media screen and (max-width:785px) { 
    .doodle{ 
        left: 0;
        width: 130vw;
    }
	
}


@media screen and (max-width:530px) { 
    .doodle{
        left: 50px;
        width: 150vw;
        height: 100vh;
    }
    .home-div{
        width: 85vw;
        height: 90vh;
        font-size: 1rem;
    }
    .typeface{
        font-size: 3rem;
    }
	
}


@media screen and (max-width:475px) { 
    .doodle{
        top: -500px;
        left: 120px;
        width: 175vw;
    }
}

